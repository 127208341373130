import React from "react";
import { useParams } from "react-router-dom";
import '@materializecss/materialize/dist/css/materialize.min.css';
import './style.css';

function PlayerPage() {
  const { tenantId, contentId } = useParams();

  // React.useEffect(()=>{
  //   const childWindow = document.getElementById('embed-player')?.contentWindow;
  //   if (childWindow) {
  //       window.addEventListener('message', message => {
  //           console.log(message);
  //           if (message.source !== childWindow) {
  //               console.log('BAD EVENT');
  //               return; // Skip message in this event listener
  //           }
  //           console.log('GOOD EVENT', message.data);
  //       });
  //   }
  // },[])

  return (
    <>
      <div className="row">
        <div className="col s12 left-align">
          <img className="responsive-img" src="/pineapple-academy-horizontal-logo.png" />
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="container">
            <div className="videoWrapper">
              <iframe src={`/embed/${tenantId}/${contentId}${window.location.search}`} frameBorder="0" allowFullScreen id="embed-player"></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlayerPage;