import { Routes, Route, Navigate } from "react-router-dom"
import PlayerPage from "./components/player"
import EmbedPage from "./components/embed"
import DefaultPage from "./components/default"
import './App.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/embed/:tenantId/:contentId" element={<EmbedPage />} />
        <Route path="/:tenantId/:contentId" element={<PlayerPage />} />
        <Route path="/" element={<DefaultPage />} />
        <Route
          path="*"
          element={<Navigate to="/" />}
        />
      </Routes>
    </div>
  );
}

export default App;
