import React from 'react';
// funky error https://github.com/videojs/video.js/issues/8170
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from '!video.js';
import 'video.js/dist/video-js.css';
// import 'videojs-seek-buttons/dist/videojs-seek-buttons.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
import seekButtons from './plugin/videojs-seek-buttons';

export const VideoJS = (props) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const { options, onReady } = props;

    React.useEffect(() => {
        // Make sure Video.js player is only initialized once
        videojs.Vhs.xhr.beforeRequest = (opt) => {
            if (!options.pa?.entitlementId) return;
            if (!opt.headers) {
                opt.headers = {}
            }
            opt.headers["x-pa-session-id"] = options.pa.entitlementId;
            return opt;
        };

        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
            const videoElement = document.createElement("video-js");

            videoElement.setAttribute('webkit-playsinline', 'webkit-playsinline');
            videoElement.setAttribute('playsinline', 'playsinline');

            videoElement.classList.add('vjs-fluid');
            videoElement.classList.add('vjs-default-skin');
            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, options
                , () => {
                    videojs.log('player is ready');
                    onReady && onReady(player);
                }
            );

            if (options?.pa?.enableProgress === false) {
                player.controlBar.progressControl.disable();
            }

            player.seekButtons({
                back: 30
            });

            if (options?.pa?.startSecond) {
                player.currentTime(options.pa.startSecond)
            }

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            const player = playerRef.current;
            player.autoplay(options.autoplay);
            player.src(options.sources);

            if (options.pa.startSecond) {
                player.currentTime(options.pa.startSecond)
            }
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player>
            <div ref={videoRef} playsinline/>
        </div>
    );
}

export default VideoJS;