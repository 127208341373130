import React from "react";

function DefaultPage() {
  return (
    <div>
      <h1>... this is not the page you are looking for ...</h1>
    </div>
  );
}

export default DefaultPage;