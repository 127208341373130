import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import '@materializecss/materialize/dist/css/materialize.min.css';
import './style.css';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import VideoJS from "../video";

function EmbedPage() {
  const { tenantId, contentId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const playerRef = React.useRef(null);

  const [fpHash, setFpHash] = React.useState('');
  const [videoOptions, setVideoOptions] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [introVisible, setIntroVisible] = React.useState(false);
  const [introSecond, setIntroSecond] = React.useState(null);
  const [endSecond, setEndSecond] = React.useState(null);
  const [outroSecond, setOutroSecond] = React.useState(null);
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
 
  React.useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      setFpHash(visitorId);
    };
    setFp();
  }, []);

  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  React.useEffect(() => {
    const stubData = async () => {
      return {
        data: {
          id: 'asdfasdf',
          status: 'allow',
          asset: {
             url: 'https://cdn.bitmovin.com/content/assets/sintel/hls/playlist.m3u8',
            //url: 'http://127.0.0.1:8000/api/v1.0/watch/b6atyxqquf/780nyua9twk2vh73/xxx/hls/index.m3u8',
            // poster: 'https://media.istockphoto.com/id/483452183/photo/close-up-of-agriculture-red-tractor-cultivating-field-over-blue-sky.jpg?s=612x612&w=0&k=20&c=yVDV3OUfWC5kigUGnOgNtirixdFCosvPGyMiSs3cHw0=',
            tracks: [],
            intro: 10,
            outro: 20
          }
        }
      };
    };

    const fetchData = async () => {
      const alias = searchParams.get("alias") ?? '';
      const member_id = searchParams.get("member_id") ?? '';
      const student_id = searchParams.get("student_id") ?? '';
      try {
        const options = {
          method: 'POST',
          body: JSON.stringify({
            tenant_id: tenantId,
            video_id: contentId,
            alias: alias,
            member_id: member_id,
            student_id: student_id,
            fingerprint: fpHash,
            metadata: {
              os: getOS(),
              user_agent: navigator.userAgent,
              lang: navigator.language,
              tz: -(new Date().getTimezoneOffset() / 60),
            }
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        };
        const response = await fetch('https://backend.pineappleacademy.com/api/v1.0/watch/entitlement/', options);
        
        const responseJs = await response.json();
        if (response?.ok) {
          return responseJs;
        } else {
          return {data: { ...responseJs, status: 'error'}}
        }
      } catch (error) {
        console.error(error);
        return {data: { status: 'error', message: error }}
      }
    };

    if (fpHash && tenantId && contentId) {
      //stubData()
      fetchData()
        .then((res) => {
          const { data } = res;
          if (data?.status === "allow") {
            const ts = parseInt(searchParams.get("startSecond") ?? '0');
            const es = parseInt(searchParams.get("endSecond") ?? '0');
            const posterOverride = decodeURIComponent(searchParams.get("poster") ?? '');
            const eb = searchParams.get("progress") !== '0';
            const allowAutoplay = searchParams.get("autoplay") !== "0";

            const is_ios = /iP(ad|od|hone)/i.test(window.navigator.userAgent);
            const is_safari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

            const allowFullscreen = is_ios && is_safari && !eb ? false : true;
            
            setIntroSecond(data.asset.intro ?? 0);
            setOutroSecond(data.asset.outro);

            if (es) {
              setEndSecond(es);
            }

            setVideoOptions({
              controls: true,
              autoplay: allowAutoplay,
              
              preload: "metadata",
              poster: posterOverride ?? data.asset.poster,
              controlBar: {
                pictureInPictureToggle: false,
                fullscreenToggle: allowFullscreen,
              },
              html5: {
                nativeControlsForTouch: false,
                nativeTextTracks: true,
                nativeAudioTracks: false,
                nativeVideoTracks: false,
                hls: {
                  enableLowInitialPlaylist: true,
                  smoothQualityChange: true,
                  overrideNative: true,
                },
              },
              playbackRates: [0.5, 1, 1.5, 1.75, 2],
              sources: [{
                type: "application/x-mpegURL",
                src: `${data.asset.url}?access_token=${data.id}`
              }],
              // tracks: data.asset?.tracks?.map((x) => { return { ...x, src: `${x.src}?access_token=${data.id}` } }),
              pa: {
                entitlementId: data.id,
                startSecond: ts,
                enableProgress: eb
              }
            });
            setLoading(false);
          }
          else {
            setErrorMessage(data?.message);
            setIsError(true);
          }
        });
    }
  }, [fpHash, tenantId, contentId, searchParams])

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('error', (event) =>{
      setLoading(true);
      setIsError(true);
      console.log('!!!! SOME ERROR', player.error());
    })

    player.on('ended', (event) => {
      postEnd();
    });

    player.on('timeupdate', (event) => {
      let currentTime = player.currentTime();
      const outro = ((outroSecond && outroSecond < currentTime) || (endSecond && endSecond < currentTime)) ?? false;
      const intro = (introSecond && introSecond > 0 && introSecond > currentTime);

      window.parent?.postMessage({ type: "timeupdate", currentTime, outro, intro }, "*");

      if (intro) {
        setIntroVisible(true);
      } else {
        setIntroVisible(false);
      }

      if (endSecond && endSecond < currentTime) {
        player.pause();
        postEnd();
      }

    });
  };

  const postEnd = () => {
    window.parent?.postMessage({ type: "ended" }, "*");
  }

  return (
    (!loading ?
      <div className="videoplayer_player">
        <VideoJS options={videoOptions} onReady={handlePlayerReady} />
        {(introVisible && <div className="playpause">
          <a className="waves-effect waves-light btn-small introButton" onClick={() => {
            playerRef.current.currentTime(introSecond);
          }}>Skip Intro</a>
        </div>)}
      </div>
      : isError ?
        <>
          <h2>ERROR</h2>
          <div className="pineappleContainer">
          <img className="responsive-img"  src="/4-sad-pina-tear-no-shadow-tm.png" />
          <p>{errorMessage}</p>
        </div>
        </>
        : <div className="pineappleContainer">
          <div className="profile1" style={{ position: 'relative' }}>
            <div className="pineapple">
              <div className="leaf middle"></div>
              <div className="leaf left"></div>
              <div className="leaf right"></div>
              <div className="shadow"></div>
              <div className="body">
                <div className="eye left"></div>
                <div className="eye right"></div>
                <div className="mouth"></div>
                <div className="arm left"></div>
                <div className="arm right"></div>
                <div className="leg left"></div>
                <div className="leg right"></div>
              </div>
            </div>
          </div>
        </div>
    )
  );
}

export default EmbedPage;